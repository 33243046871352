import { canUseDOM } from 'src/utils/can-use-dom';
import { getDateTimeString } from '../datetime';
export function logInfo(info) {
    if (canUseDOM) {
        return;
    }
    console.log(`${getDateTimeString()}|INFO|${info}`);
}
export function logError(error) {
    if (canUseDOM) {
        return;
    }
    console.error(`${getDateTimeString()}|ERROR|error=${error}`);
}
