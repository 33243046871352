import * as React from 'react';
import Loading from '@nebula/loading';
import { css } from 'linaria';
const container = css `
  color: var(--color-primary);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export default function PageLoading() {
    return React.createElement(Loading, { className: container });
}
