import { SET_THEME } from './actionTypes';
import { defaultTheme } from 'src/styles/themes';
const initialState = {
    theme: defaultTheme,
};
export function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_THEME:
            const { theme } = action.payload || {};
            return { ...state, theme };
        default:
            return state;
    }
}
