// For TrackingSDKV3
export const TRACKING_APP_ID = 18;
export const TRACKING_SOURCE = 'mweb';
export const ENABLE_VALIDATION = __TRACKING_VALIDATION_URL__ !== ''; // TRACKING_VALIDATION_URL is empty in production env
export const TRACKING_VALIDATION_URL = `${__TRACKING_VALIDATION_URL__}/api/validator/${TRACKING_APP_ID}/s`;
/**
 * The percentage of an element that must intersect with user's viewport
 */
export const INTERSECTION_PERCENTAGE = 0.5;
/**
 * How long an element has to keep staying within user's viewport to make it a valid impression
 */
export const IMPRESSION_DURATION = 1000;
