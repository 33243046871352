import { replacePlaceholders } from '@shopee/i18n-placeholder-replace';
import { getBundledTranslation } from './util';
import tNodeFactory from './tNode';
// Constants
import { DEFAULT_LANGUAGE } from 'src/_shared/config';
function createI18N() {
    const collections = new Set();
    const translations = {};
    const i18n = {};
    function filterUnavailableTranslation(collectionIds) {
        return collectionIds.filter((collectionId) => !collections.has(collectionId));
    }
    i18n.checkCollection = filterUnavailableTranslation;
    i18n.fetchTranslation = async ({ langCode = DEFAULT_LANGUAGE, collectionIds, }) => {
        const unavailableCollections = filterUnavailableTranslation(collectionIds);
        if (unavailableCollections.length > 0) {
            const responses = await Promise.all(unavailableCollections.map((collectionId) => {
                return getBundledTranslation(langCode, collectionId)
                    .then((translation) => ({ collectionId, translation }))
                    .catch((error) => console.log(error));
            }));
            responses.forEach((response) => {
                if (!response)
                    return;
                const { collectionId, translation } = response;
                collections.add(collectionId);
                Object.assign(translations, translation);
            });
        }
    };
    i18n.t = (key, opts) => {
        if (!translations) {
            return key;
        }
        if (Object.prototype.hasOwnProperty.call(translations, key)) {
            return replacePlaceholders(translations[key], opts);
        }
        // not found, using original key
        return key;
    };
    i18n.tNode = tNodeFactory(i18n.t);
    return i18n;
}
export default createI18N;
