// extracted from @shopee/settings
import { LANGUAGES } from 'src/_shared/constants';
export const CURRENCY = {
    IDR: 'IDR',
};
export const COUNTRY_CONFIG = {
    default: {
        language: LANGUAGES.id,
        currency: CURRENCY.IDR,
        countryName: 'Indonesia',
        adultAge: 21,
        languages: [],
    },
    ID: {
        language: LANGUAGES.id,
        currency: CURRENCY.IDR,
        countryName: 'Indonesia',
        adultAge: 21,
        languages: [],
    },
};
