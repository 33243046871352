import * as React from 'react';
const RENDER_TIMEOUT = 10000; // 10s TODO: @tranct @louis.nguyen observe this value, if the network is bad, we can increase to 20s
const IDLE_TIMEOUT = 1000;
export default function useDeferredInitialization(callback) {
    const [initialized, setInitialized] = React.useState(false);
    const [timeoutId, setTimeoutId] = React.useState(null);
    // To detect if the defer is timeout
    const [isTimeout, setIsTimeout] = React.useState(false);
    // To detect if the page has completed the critical rendering
    const [hasCompletedCriticalRender, setHasCompletedCriticalRender,] = React.useState(false);
    const setReady = React.useCallback(() => {
        window.requestIdleCallback(() => setHasCompletedCriticalRender(true), {
            timeout: IDLE_TIMEOUT,
        });
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    }, [timeoutId]);
    React.useEffect(() => {
        const id = setTimeout(() => {
            setIsTimeout(true);
        }, RENDER_TIMEOUT);
        setTimeoutId(id);
        return () => clearTimeout(id);
    }, []);
    React.useEffect(() => {
        if (!initialized && (hasCompletedCriticalRender || isTimeout)) {
            callback();
            setInitialized(true);
        }
    }, [hasCompletedCriticalRender, isTimeout, initialized, callback]);
    return { setReady, isTimeout };
}
