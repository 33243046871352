// using string enum, see https://git.garena.com/shopee/mall-fe/core-fe-aq/growth-dropshipper-web/merge_requests/103#note_2768703
// We need to interate through the collection id, so we can not use `const enum`
export var TransifyCollectionID;
(function (TransifyCollectionID) {
    TransifyCollectionID["PageCatalogue"] = "172";
    TransifyCollectionID["PageProduct"] = "173";
    TransifyCollectionID["PageNotFound"] = "174";
    TransifyCollectionID["PageNewArrival"] = "185";
    TransifyCollectionID["PageSearch"] = "186";
    TransifyCollectionID["FilterPanel"] = "187";
})(TransifyCollectionID || (TransifyCollectionID = {}));
export var LANGUAGES;
(function (LANGUAGES) {
    LANGUAGES["en"] = "en";
    LANGUAGES["id"] = "id";
    LANGUAGES["ms"] = "ms";
    LANGUAGES["zh-Hans"] = "zh-Hans";
    LANGUAGES["zh-Hant"] = "zh-Hant";
    LANGUAGES["th"] = "th";
    LANGUAGES["vi"] = "vi";
    LANGUAGES["pt-BR"] = "pt-BR";
})(LANGUAGES || (LANGUAGES = {}));
