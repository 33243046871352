import { LANGUAGES } from 'src/_shared/constants';
import { COUNTRY_CONFIG } from './country';
import DOMAIN from '../../../domain.json';
export const ENV = __ENV__;
export const LOCALE = __LOCALE__;
export const LANGUAGE_COOKIE = 'lang';
export const DEFAULT_LANGUAGE = (COUNTRY_CONFIG[LOCALE] || {}).language || LANGUAGES.en;
export const BASE_URL_DROPSHIPPER = `https://${DOMAIN.env[ENV]}katalogproduk${DOMAIN.cid[LOCALE]}`;
export const TRACKING_URL_ENDPOINT = `https://c-api-bit.${DOMAIN.env[ENV]}katalogproduk${DOMAIN.cid[LOCALE]}/${LOCALE.toLowerCase()}/tr`;
export const BASE_API_URL = __MOCK_API_HOST__ || BASE_URL_DROPSHIPPER;
export const ENABLE_SW = __ENABLE_SW__;
export const FOR_SEO = __FOR_SEO__;
