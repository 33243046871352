import { generateAsyncRouteComponent } from 'src/utils/universal-render';
import { TransifyCollectionID } from 'src/_shared/constants';
import { ROUTE_CATALOGUE, ROUTE_NEW_ARRIVAL, ROUTE_PRODUCT, ROUTE_SEARCH, } from './constants';
// Placeholder UI
import PageLoading from 'src/_shared/components/PageLoading';
export const ROUTES = [
    {
        path: ROUTE_SEARCH,
        name: "PageSearch" /* PageSearch */,
        exact: true,
        component: generateAsyncRouteComponent({
            loader: () => import(/* webpackChunkName: "PageSearch" */ '../Search'),
            collectionIds: [TransifyCollectionID.PageSearch],
            placeholder: PageLoading,
        }),
    },
    {
        path: ROUTE_PRODUCT,
        name: "PageProduct" /* PageProduct */,
        exact: true,
        component: generateAsyncRouteComponent({
            loader: () => import(/* webpackChunkName: "PageProduct" */ '../Product'),
            collectionIds: [TransifyCollectionID.PageProduct],
            placeholder: PageLoading,
        }),
    },
    {
        path: ROUTE_CATALOGUE,
        name: "PageCatalogue" /* PageCatalogue */,
        exact: true,
        component: generateAsyncRouteComponent({
            loader: () => import(/* webpackChunkName: "PageCatalogue" */ '../Catalogue'),
            collectionIds: [TransifyCollectionID.PageCatalogue],
            placeholder: PageLoading,
        }),
    },
    {
        path: ROUTE_NEW_ARRIVAL,
        name: "PageNewArrival" /* PageNewArrival */,
        exact: true,
        component: generateAsyncRouteComponent({
            loader: () => import(/* webpackChunkName: "PageNewArrival" */ '../NewArrival'),
            collectionIds: [TransifyCollectionID.PageNewArrival],
            placeholder: PageLoading,
        }),
    },
    {
        path: '*',
        name: "Page404" /* Page404 */,
        component: generateAsyncRouteComponent({
            loader: () => import(/* webpackChunkName: "PageNotFound" */ '../PageNotFound'),
            collectionIds: [TransifyCollectionID.PageNotFound],
            placeholder: PageLoading,
        }),
    },
];
