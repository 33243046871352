import { createContext } from 'react';
import { DEFAULT_LANGUAGE } from 'src/_shared/config';
export const globalContextDefault = {
    cookies: {},
    userAgent: '',
    language: DEFAULT_LANGUAGE,
    lazyLoadObserver: null,
    url: {
        pathname: '',
        search: '',
    },
};
export const GlobalContext = createContext(globalContextDefault);
