// Polyfill for Web APIs only
import './rIC';
import './rAF';
export function polyfillEnvironment() {
    const promises = [];
    // `intersection-observer` and `whatwg-fetch` will do the global patch
    // we just need to import it
    if (!window.IntersectionObserver) {
        promises.push(import(
        /* webpackChunkName: "intersection-observer" */ 'intersection-observer'));
    }
    if (!window.fetch) {
        promises.push(import(/* webpackChunkName: "fetch" */ 'whatwg-fetch'));
    }
    return Promise.all(promises);
}
