import { ENV } from 'src/_shared/config';
// Essential config for the Firebase performance standalone only
// Firebase documentation asks us to config more, however, it is not really neccessary
// This dev config is used in non-live and localhost for development purpose
const DEV_FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBYF35WDUiDS8g4AizH4J2gaxBnsHYX65Q',
    projectId: 'dropshipper-9c0de',
    appId: '1:112303871483:web:e9f408dfb2e82aea27675e',
};
const PROD_FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBYF35WDUiDS8g4AizH4J2gaxBnsHYX65Q',
    projectId: 'dropshipper-9c0de',
    appId: '1:112303871483:web:ece9ee4a48c05a6c27675e',
};
export const FIREBASE_CONFIG = ENV === 'live' ? PROD_FIREBASE_CONFIG : DEV_FIREBASE_CONFIG;
