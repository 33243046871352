import { ENV, LOCALE, TRACKING_URL_ENDPOINT } from 'src/_shared/config';
import { TRACKING_APP_ID, TRACKING_SOURCE, TRACKING_VALIDATION_URL, ENABLE_VALIDATION, } from 'src/_shared/config/tracking';
import { postEventValidation } from './validate';
// Utils
import { isIOS } from '../user-agent';
import batchFunction from './batchFunction';
const IDLE_TIMEOUT = 1000; // 1s
const BATCH_TIME = 100;
function createTrackingEvent(info) {
    return {
        type: 'v3',
        timestamp: Date.now(),
        info,
    };
}
export default function createBITracker({ cookies, userAgent, }) {
    let tracker = undefined;
    let eventBuffer = [];
    const deviceId = cookies['SPC_F'] || `client_generated_deviceid_${new Date().getTime()}`;
    const initTracker = async () => {
        if (tracker === undefined) {
            const { createTracker } = await import(
            /* webpackChunkName: "trackingSDK" */ './trackingSDK');
            tracker = createTracker({
                appId: TRACKING_APP_ID,
                source: TRACKING_SOURCE,
                env: ENV,
                locale: LOCALE,
                endpoint: TRACKING_URL_ENDPOINT,
                platform: isIOS(userAgent)
                    ? "mweb_ios" /* IOS */
                    : "mweb_android" /* Android */,
                getCookies: () => {
                    return { ...cookies, SPC_F: deviceId };
                },
                ua: userAgent,
                enableAutoTrack: false,
                fetch: self.fetch.bind(self),
            });
            // flush all tracking events in the buffer
            for (let i = 0; i < eventBuffer.length; i++) {
                if (tracker === undefined) {
                    continue;
                }
                tracker.sync({ data: eventBuffer[i] });
            }
            eventBuffer = [];
        }
        else {
            console.warn('BI Tracker is init already');
        }
    };
    async function validateEvent(event, schema) {
        if (ENABLE_VALIDATION && schema) {
            const response = await postEventValidation(TRACKING_VALIDATION_URL, [
                {
                    id: schema,
                    value: event,
                },
            ]);
            if (response.errorCode !== undefined) {
                console.error(response.errorCode, response.errorMessage);
            }
            else {
                response.result.forEach((eventResult) => {
                    if (!eventResult.result) {
                        console.error('Invalid tracking event', eventResult.event);
                    }
                });
            }
        }
    }
    function sendEvent(event, schema) {
        if (tracker === undefined) {
            eventBuffer.push(event);
        }
        else {
            window.requestIdleCallback(() => {
                if (tracker === undefined) {
                    return;
                }
                tracker.sync({ data: event });
                validateEvent(event, schema);
            }, { timeout: IDLE_TIMEOUT });
        }
    }
    function sendTrackingEvent(event) {
        const { schema, ...eventData } = event;
        sendEvent(createTrackingEvent(eventData), schema);
    }
    const sendImpressionInBatch = batchFunction((events) => {
        const eventMap = {};
        const schemaMap = {};
        events.forEach((event) => {
            const { page_type, page_section, target_type, operation, data, schema, } = event;
            const key = schema || JSON.stringify({ page_type, page_section, target_type });
            if (!eventMap[key]) {
                eventMap[key] = {
                    operation,
                    page_type,
                    page_section,
                    target_type,
                    data: { viewed_objects: [] },
                };
                schemaMap[key] = schema;
            }
            const impressionTrackingData = eventMap[key];
            impressionTrackingData.data.viewed_objects.push({ ...data });
        });
        for (const key in eventMap) {
            sendEvent(createTrackingEvent(eventMap[key]), schemaMap[key]);
        }
    }, BATCH_TIME);
    function sendImpressionEvent(event) {
        sendImpressionInBatch(event);
    }
    return {
        initTracker,
        sendTrackingEvent,
        sendImpressionEvent,
    };
}
