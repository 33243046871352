import { canUseDOM } from '../can-use-dom';
export function createIntersectionObserver({ root, rootMargin, threshold, }) {
    // we do not need observe/unobserve in SSR
    // so stub it with empty function
    if (!canUseDOM) {
        return {
            observe: () => undefined,
            unobserve: () => undefined,
        };
    }
    const elementToCallbackMap = new WeakMap();
    const initialStatusDeterminedSet = new WeakSet();
    const observerInstance = new IntersectionObserver((entries) => {
        for (const entry of entries) {
            const { onEnter, onLeave, onChange } = elementToCallbackMap.get(entry.target) || {};
            const notInitialStatus = initialStatusDeterminedSet.has(entry.target);
            if (entry.isIntersecting) {
                onEnter !== undefined && onEnter();
            }
            else if (notInitialStatus) {
                onLeave !== undefined && onLeave();
            }
            initialStatusDeterminedSet.add(entry.target);
            onChange !== undefined && onChange();
        }
    }, {
        root,
        rootMargin,
        threshold,
    });
    const observe = (element, callbackObj) => {
        elementToCallbackMap.set(element, callbackObj);
        observerInstance.observe(element);
    };
    const unobserve = (element) => {
        // check if element exists in Map, remove then unobserve
        if (elementToCallbackMap.has(element)) {
            elementToCallbackMap.delete(element);
            observerInstance.unobserve(element);
        }
    };
    return {
        observe,
        unobserve,
    };
}
