// @flow
import * as React from 'react';
const PLACEHOLDER_REGEX = /[{][{](.*?)(?:!(.+?))?[}][}]/g;
export default function (t) {
    return function (key, opts) {
        // example: i18n.tNode('label_yes', { test: <a href="#">Hello</a> })
        const translatedString = t(key);
        // translatedString = yes {{test}}
        const reactNodeArray = splitByPlaceholderAndReplace(PLACEHOLDER_REGEX, translatedString, opts);
        // reactNodeArray = ['yes ', React.Element<'a'>]
        return joinReactNode(reactNodeArray);
        // yes <a href="#">Hello</a>
    };
}
function joinReactNode(strArray) {
    return strArray.map(function (textOrComponent, index) {
        if (typeof textOrComponent === 'string') {
            return textOrComponent;
        }
        else if (textOrComponent && React.isValidElement(textOrComponent)) {
            return React.cloneElement(textOrComponent, { key: index });
        }
        else {
            throw Error('Only String or valid React Element could be passed');
        }
    });
}
function isRawStringOrNumber(obj) {
    const type = typeof obj;
    return type === 'string' || type === 'number';
}
function mergeRawStrings(mergedArray, currentElement) {
    const lastIndex = mergedArray.length - 1;
    const lastMergedElement = mergedArray[lastIndex];
    if (isRawStringOrNumber(lastMergedElement) &&
        isRawStringOrNumber(currentElement)) {
        return [
            ...mergedArray.slice(0, lastIndex),
            String(lastMergedElement) + String(currentElement),
        ];
    }
    else if (currentElement) {
        return [...mergedArray, currentElement];
    }
    return mergedArray;
}
function splitByPlaceholderAndReplace(regex, str, data) {
    const templateDetection = new RegExp(regex);
    const aggregatorReactNode = [];
    let match;
    let cursor = 0;
    while ((match = templateDetection.exec(str))) {
        const [literal, key] = match;
        const { index } = match;
        const prevPart = str.substring(cursor, index);
        if (prevPart) {
            aggregatorReactNode.push(prevPart);
        }
        // Replace placeholders
        const replacement = data ? data[key] : null;
        if (replacement) {
            aggregatorReactNode.push(replacement);
        }
        cursor = index + literal.length;
    }
    const nextPart = str.substring(cursor, str.length);
    if (nextPart) {
        aggregatorReactNode.push(nextPart);
    }
    return aggregatorReactNode.reduce(mergeRawStrings, []);
}
