// extracted from @shopee/settings
import { LANGUAGES } from 'src/_shared/constants';
export const TRANSLATION_CONFIG = {
    LANGUAGE: {
        [LANGUAGES.en]: '/en',
        [LANGUAGES.id]: '/id',
        [LANGUAGES.ms]: '/ms-my',
        [LANGUAGES.th]: '/th',
        [LANGUAGES.vi]: '/vi',
        [LANGUAGES['zh-Hans']]: '/zh-hans',
        [LANGUAGES['zh-Hant']]: '/zh-hant',
        [LANGUAGES['pt-BR']]: '/pt-br',
        default: '/en',
    },
};
