export async function postEventValidation(url, body) {
    const response = await fetch(url, {
        method: 'post',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    try {
        const result = await response.json();
        if (!result.errorCode) {
            return { result };
        }
        else {
            return result;
        }
    }
    catch (error) {
        return {
            errorCode: error.name,
            errorMessage: error.message,
        };
    }
}
