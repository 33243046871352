import * as React from 'react';
import { ensureReady } from './utils/universal-render';
import { hydrate } from 'react-dom';
import { ROUTES } from './routes';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// Redux
import { Provider } from 'react-redux';
import { createStoreManager } from './redux';
// Context
import { GlobalContext } from './_shared/context/global';
import { TrackingContext } from './_shared/context/tracking';
import { FirebasePerformanceContext } from './_shared/context/firebase-performance';
import { TranslationContext } from 'src/utils/i18n/context';
// Utils
import { getUserLanguage } from './utils/language';
import { getCookies, getCookie } from '@shopee/cookie';
import { createIntersectionObserver } from 'src/utils/intersection-observer';
import createI18N from './utils/i18n';
import createBITracker from './utils/tracking/createBITracker';
import createFirebasePerformanceTracker from './utils/firebase-performance';
// Constants
import { LANGUAGE_COOKIE, ENABLE_SW } from './_shared/config';
import { INTERSECTION_PERCENTAGE as TRACKING_THRESHOLD } from './_shared/config/tracking';
// Polyfill
import { polyfillEnvironment } from './utils/polyfill/client';
// Types
import { InjectReducerContext } from './_shared/context/inject-reducer';
import App from './App';
const container = document.getElementById('app');
function registerServiceWorker() {
    // https://developers.google.com/web/fundamentals/primers/service-workers/registration
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function () {
            navigator.serviceWorker.register(
            // on production site, NGINX configuration symlinks /sw.js to /assets/sw.js
            // on development site, webpack/dev.js symlinks /sw.js to /assets/sw.js
            // Note that SW only works if the sw.js file is served at root
            '/sw.js');
        });
    }
}
async function initApp() {
    // polyfill Web API on demand
    // JS will be polyfilled by babel-preset-env
    await polyfillEnvironment();
    // Redux
    const { store, injectAsyncReducer } = createStoreManager(window.__STORE__);
    // translatiion
    const i18n = createI18N();
    const language = getUserLanguage(getCookie(LANGUAGE_COOKIE));
    // observer
    const lazyLoadObserver = createIntersectionObserver({
        rootMargin: '0px 0px 50px 0px',
    });
    const trackingObserver = createIntersectionObserver({
        rootMargin: '0px 0px 0px 0px',
        threshold: TRACKING_THRESHOLD,
    });
    // trackers
    const { initTracker: initBITracker, sendTrackingEvent, sendImpressionEvent, } = createBITracker({
        cookies: getCookies(),
        userAgent: navigator.userAgent,
    });
    const { initTracker: initFirebasePerformanceTracker, recordPerformance, } = createFirebasePerformanceTracker();
    await ensureReady({
        routeConfig: ROUTES,
        i18n,
        language,
    });
    const { pathname, search } = window.location;
    hydrate(React.createElement(GlobalContext.Provider, { value: {
            cookies: getCookies(),
            userAgent: navigator.userAgent,
            language,
            lazyLoadObserver,
            url: {
                pathname,
                search,
            },
        } },
        React.createElement(Provider, { store: store },
            React.createElement(BrowserRouter, null,
                React.createElement(TranslationContext.Provider, { value: { i18n, language } },
                    React.createElement(TrackingContext.Provider, { value: {
                            initTracker: initBITracker,
                            sendTrackingEvent,
                            sendImpressionEvent,
                            trackingObserver,
                        } },
                        React.createElement(FirebasePerformanceContext.Provider, { value: {
                                initTracker: initFirebasePerformanceTracker,
                                recordPerformance,
                            } },
                            React.createElement(InjectReducerContext.Provider, { value: { injectAsyncReducer } },
                                React.createElement(HelmetProvider, null,
                                    React.createElement(App, null))))))))), container);
    if (ENABLE_SW) {
        registerServiceWorker();
    }
    // Garbage window store
    window.__STORE__ && delete window.__STORE__;
}
initApp();
