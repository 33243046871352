export function reducer(state = {}, action) {
    switch (action.type) {
        case "CATALOGUE/FETCH_CATALOGUE_INFO_REQUESTED" /* REQUESTED */:
            return {
                ...state,
                [action.payload.username]: {
                    ...state[action.payload.username],
                    progress: 1 /* Requested */,
                },
            };
        case "CATALOGUE/FETCH_CATALOGUE_INFO_FAILED" /* FAILED */:
            return {
                ...state,
                [action.payload.username]: {
                    ...state[action.payload.username],
                    progress: 3 /* Failed */,
                    serverRendered: action.payload.serverRendered,
                    data: null,
                    error: action.payload.error,
                },
            };
        case "CATALOGUE/FETCH_CATALOGUE_INFO_SUCCESS" /* SUCCESS */:
            return {
                ...state,
                [action.payload.username]: {
                    ...state[action.payload.username],
                    progress: 2 /* Success */,
                    serverRendered: action.payload.serverRendered,
                    data: action.payload.data,
                    error: 0,
                },
            };
        default:
            return state;
    }
}
