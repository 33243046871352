import { createContext, useContext } from 'react';
import { DEFAULT_LANGUAGE } from 'src/_shared/config';
export const TranslationContext = createContext({
    i18n: {
        fetchTranslation: async () => undefined,
        checkCollection: (ids) => ids,
        t: (key) => key,
        tNode: (key) => key,
    },
    language: DEFAULT_LANGUAGE,
});
export function useTranslation() {
    return useContext(TranslationContext);
}
