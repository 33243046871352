import { canUseDOM } from 'src/utils/can-use-dom';
import { logError, logInfo } from '../logger';
function getCookieStringFromObj(obj) {
    const res = [];
    for (const k in obj) {
        res.push(`${k}=${obj[k]}`);
    }
    return res.join('; ');
}
// extend the options with serverOptions
function extendRequestOptions(options = {}, serverOptions) {
    if (!serverOptions) {
        return options;
    }
    return {
        ...options,
        headers: {
            ...options.headers,
            cookies: getCookieStringFromObj(serverOptions.cookies),
        },
    };
}
async function http(url, options, serverOptions) {
    const response = await fetch(url, canUseDOM ? options : extendRequestOptions(options, serverOptions));
    // We can have headers here, just in case
    if (response.status >= 200 && response.status < 300) {
        const parsedBody = await response.json();
        logInfo(`url=${url},options=${JSON.stringify(options)},response=${JSON.stringify(parsedBody)}`);
        return {
            parsedBody,
            headers: response.headers,
        };
    }
    else {
        logError(response.statusText);
        throw new Error(response.statusText);
    }
}
export async function get(url, options = {}, serverOptions) {
    try {
        // prettier-ignore
        const response = await http(url, {
            method: 'get',
            credentials: 'include',
            ...options
        }, serverOptions);
        return response.parsedBody;
    }
    catch (error) {
        return {
            error: error.name === 'timeout'
                ? -99 /* ServerTimeout */
                : -999 /* ServerError */,
            error_msg: error.message,
        };
    }
}
export async function post(url, body, options = {}, serverOptions) {
    try {
        // prettier-ignore
        const response = await http(url, {
            method: 'post',
            body: JSON.stringify(body),
            ...options
        }, serverOptions);
        return response.parsedBody;
    }
    catch (error) {
        return {
            error: error.name === 'timeout'
                ? -99 /* ServerTimeout */
                : -999 /* ServerError */,
            error_msg: error.message,
        };
    }
}
