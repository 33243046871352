import { FIREBASE_CONFIG } from 'src/_shared/config/firebase';
const FIREBASE_CDN_URL = 'https://www.gstatic.com/firebasejs/7.23.0/firebase-performance-standalone.js';
function addScript(id, src) {
    return new Promise((resolve, reject) => {
        const element = document.getElementById(id);
        if (element) {
            return resolve(true);
        }
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('id', id);
        script.setAttribute('src', src);
        script.addEventListener('load', () => resolve(true));
        script.addEventListener('error', () => reject(new Error(`Error loading ${id}.`)));
        script.addEventListener('abort', () => reject(new Error(`${id} loading aborted.`)));
        document.getElementsByTagName('head')[0].appendChild(script);
    });
}
async function initFirebasePerformance() {
    await addScript('firebase-performance', FIREBASE_CDN_URL);
    return window.firebase.initializeApp(FIREBASE_CONFIG).performance();
}
export default function createFirebasePerformanceTracker() {
    let firebasePerformance = undefined;
    let recordBuffer = [];
    let isReadyToSendTracking = false;
    const initTracker = async () => {
        if (firebasePerformance === undefined) {
            firebasePerformance = await initFirebasePerformance();
            isReadyToSendTracking = true;
            // send record in buffer to Firebase
            for (let i = 0; i < recordBuffer.length; i++) {
                sendTrackingToFirebase(recordBuffer[i]);
            }
            recordBuffer = [];
        }
        else {
            console.warn('Firebase performance instance is initialized already');
        }
    };
    function sendTrackingToFirebase(record) {
        const { traceName, duration, options } = record;
        const startTime = Date.now() - Math.round(duration);
        if (firebasePerformance !== undefined) {
            firebasePerformance.trace(traceName).record(startTime, duration, options);
        }
    }
    // when we call this, firebase could not be init yet
    function recordPerformance(record) {
        if (!isReadyToSendTracking) {
            recordBuffer.push(record);
        }
        else {
            sendTrackingToFirebase(record);
        }
    }
    return {
        initTracker,
        recordPerformance,
    };
}
