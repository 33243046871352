import * as React from 'react';
import cx from 'classnames';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getTheme } from './redux/theme/selector';
// Router
import { useLocation, useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { ROUTES } from './routes';
// HOC
import withTracking from './_shared/hoc/withTracking';
import withFirebasePerformance from './_shared/hoc/withFirebasePerformance';
// Hooks
import useDeferredInitialization from './_shared/hooks/useDeferredInitialization';
// Styles
import { MAX_SCREEN_WIDTH } from './styles/constants';
import { css } from 'linaria';
// Set global styles
import './styles/global';
import { RenderBeaconContext } from './_shared/context/render-beacon';
const container = css `
  margin: 0 auto;
  max-width: ${MAX_SCREEN_WIDTH}px;
`;
// We scroll to top if there is new route transition
// We check if action is `POP` then we will not scroll to top
// history.(go|goBack|goForward) and browser's forward/back will be `POP` action as well
function ScrollToTop() {
    const { pathname } = useLocation();
    const history = useHistory();
    React.useLayoutEffect(() => {
        if (history.action !== 'POP') {
            window.scrollTo(0, 0);
        }
    }, [pathname, history.action]);
    return null;
}
function App({ initBITracker, initFirebasePerformanceTracker, theme, }) {
    const { setReady, isTimeout } = useDeferredInitialization(() => {
        initBITracker();
        initFirebasePerformanceTracker();
    });
    return (React.createElement(RenderBeaconContext.Provider, { value: { setReady, isTimeout } },
        React.createElement("div", { className: cx(container, theme) },
            React.createElement(ScrollToTop, null),
            renderRoutes(ROUTES))));
}
const mapStateToProps = (state) => {
    return {
        theme: getTheme(state),
    };
};
export default compose(withTracking, withFirebasePerformance, connect(mapStateToProps))(App);
