"use strict";
let lastTime = 0;
window.requestAnimationFrame =
    window.requestAnimationFrame ||
        function (cb) {
            const currTime = new Date().getTime();
            const timeToCall = Math.max(0, 16 - (currTime - lastTime));
            const id = window.setTimeout(() => {
                cb(currTime + timeToCall);
            }, timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };
window.cancelAnimationFrame =
    window.cancelAnimationFrame ||
        function (id) {
            clearTimeout(id);
        };
