import { BASE_URL_DROPSHIPPER } from 'src/_shared/config';
export function jsonToQueryString(json, skipQuestionMark = false) {
    if (!json) {
        return '';
    }
    const keys = Object.keys(json).filter((key) => {
        const val = json[key];
        return typeof val !== 'undefined' && val !== null;
    });
    if (!keys.length) {
        return '';
    }
    return ((skipQuestionMark ? '' : '?') +
        keys
            .sort()
            .map(function (key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
        })
            .join('&'));
}
export function parseQueryStringToObj(queryString) {
    const dictionary = {};
    if (typeof queryString !== 'string' || queryString.length === 0) {
        return dictionary;
    }
    // remove the '?' from the beginning of the
    // if it exists
    if (queryString.indexOf('?') === 0) {
        queryString = queryString.substr(1);
    }
    if (!queryString.length) {
        return dictionary;
    }
    // Step 1: separate out each key/value pair
    const parts = queryString.split('&');
    for (let i = 0; i < parts.length; i++) {
        const p = parts[i];
        // Step 2: Split Key/Value pair
        const keyValuePair = p.split('=');
        // Step 3: Add Key/Value pair to Dictionary object
        const key = keyValuePair[0];
        let value = keyValuePair[1];
        // decode URI encoded string
        value && value.replace(/\+/g, '%20');
        value = decodeURIComponent(value);
        dictionary[key] = value;
    }
    // Step 4: Return Dictionary Object
    return dictionary;
}
export function getPathSearchFromUrl(url) {
    const urlSplit = url.split('?');
    const pathname = urlSplit[0];
    const search = urlSplit[1] ? `?${urlSplit[1]}` : '';
    return { pathname, search };
}
export function arrayToQueryString(stringArray) {
    return stringArray && stringArray.length ? stringArray.join(',') : undefined;
}
export function queryStringToNumberArray(querystring) {
    return querystring ? querystring.split(',').map((c) => parseInt(c, 10)) : [];
}
export function getAbsoluteUrl(url) {
    if (!url) {
        return BASE_URL_DROPSHIPPER;
    }
    const { pathname } = getPathSearchFromUrl(url);
    return `${BASE_URL_DROPSHIPPER}${pathname}`;
}
const THUMBNAIL_SUFFIX = '_tn';
export function getThumbnailUrl(imageUrl) {
    return imageUrl.endsWith(THUMBNAIL_SUFFIX)
        ? imageUrl
        : imageUrl + THUMBNAIL_SUFFIX;
}
