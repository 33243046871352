// APIs
import { fetchCatalogueInfo as apiFetchCatalogueInfo } from 'src/_shared/api/catalogue';
// Utils
import { canUseDOM } from 'src/utils/can-use-dom';
// Constants
import { REDUCER_NAME } from './constants';
function shouldSkipRefetch(serverRendered, progress) {
    return (
    // if API is sucess
    progress === 2 /* Success */ ||
        // if API is ongoing
        (!serverRendered && progress === 1 /* Requested */));
}
export const fetchCatalogueInfo = (username) => {
    return async (dispatch, getState, extraArguments) => {
        const catalogueInfo = getState()[REDUCER_NAME][username];
        const { progress, serverRendered } = catalogueInfo || {};
        if (catalogueInfo && shouldSkipRefetch(serverRendered, progress)) {
            return;
        }
        dispatch({
            type: "CATALOGUE/FETCH_CATALOGUE_INFO_REQUESTED" /* REQUESTED */,
            payload: { username },
        });
        const response = await apiFetchCatalogueInfo({ username }, { cookies: extraArguments.cookies });
        if (!response.error) {
            dispatch({
                type: "CATALOGUE/FETCH_CATALOGUE_INFO_SUCCESS" /* SUCCESS */,
                payload: { serverRendered: !canUseDOM, data: response.data, username },
            });
        }
        else {
            dispatch({
                type: "CATALOGUE/FETCH_CATALOGUE_INFO_FAILED" /* FAILED */,
                payload: {
                    serverRendered: !canUseDOM,
                    username,
                    error: response.error,
                },
            });
        }
    };
};
