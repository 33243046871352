const DEFAULT_DEBOUNCE_TIME = 100;
export function debounce(func, delay) {
    let timeoutId = undefined;
    const debounced = (...args) => {
        timeoutId !== undefined && clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
    return debounced;
}
// this batch function assumes input parameter
// to be 1 single argument of type T
export default function batchFunction(batchHandler, batchTime) {
    const argumentBuffer = [];
    const debounced = debounce(() => {
        batchHandler(argumentBuffer);
        argumentBuffer.length = 0;
    }, batchTime || DEFAULT_DEBOUNCE_TIME);
    return (args) => {
        argumentBuffer.push(args);
        debounced();
    };
}
