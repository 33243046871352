import * as React from 'react';
import { matchRoutes } from 'react-router-config';
import { TranslationContext } from 'src/utils/i18n/context';
const DELAY_TIME_TO_SHOW_PLACEHOLDER = 300;
// This is to make universal render for async routes code-splitting
// It's similar to @shopee/server-render-universal but in TS
export function generateAsyncRouteComponent({ loader, collectionIds, placeholder: Placeholder, }) {
    var _a;
    let Component = null;
    return _a = class AsyncRouteComponent extends React.Component {
            constructor() {
                super(...arguments);
                // we delay not to show the placeholder
                // so that if the loader is resolved fast, there will be no flashing in UI
                this._timeoutId = null;
                this.state = {
                    Component,
                    showPlaceholder: false,
                };
                this.updateState = () => {
                    // Only update state if we don't already have a reference to the
                    // component, this prevent unnecessary renders.
                    if (this.state.Component !== Component) {
                        this.setState({
                            Component,
                        });
                    }
                };
            }
            // load component to make sure that the component is instantiated
            // and fetch translation of the component
            componentDidMount() {
                const { i18n, language } = this.context;
                Promise.all([
                    i18n.fetchTranslation({ langCode: language, collectionIds }),
                    AsyncRouteComponent.loadComponent(),
                ]).then(() => this.updateState());
                // set timeout for showing placeholder
                this._timeoutId = setTimeout(() => {
                    this.setState({ showPlaceholder: true });
                }, DELAY_TIME_TO_SHOW_PLACEHOLDER);
            }
            componentWillUnmount() {
                this._timeoutId && clearTimeout(this._timeoutId);
            }
            render() {
                const { Component: ComponentFromState } = this.state;
                if (ComponentFromState) {
                    return React.createElement(ComponentFromState, Object.assign({}, this.props));
                }
                if (this.state.showPlaceholder && Placeholder) {
                    return React.createElement(Placeholder, Object.assign({}, this.props));
                }
                return null;
            }
        },
        _a.contextType = TranslationContext,
        /**
         * Static so that you can call load against an uninstantiated version of
         * this component. This should only be called one time outside of the
         * normal render path.
         */
        _a.loadComponent = async () => {
            const { default: defaultExport, initServerSideRender, } = await loader();
            Component = defaultExport;
            return {
                Component,
                initServerSideRender,
            };
        },
        /**
         * Static so that we can get the correct collectionIds in ensureReady
         * This should only be called once outside of the normal render path
         */
        _a.getTransifyCollections = () => collectionIds,
        _a;
}
/**
 * First match the routes via react-router-config's `matchRoutes` function.
 * Then iterate over all of the matched routes, if they've got a load function
 * call it.
 *
 * This helps us to make sure all the async code is loaded before rendering.
 */
export function ensureReady({ routeConfig, providedLocation, i18n, language, }) {
    const matches = matchRoutes(routeConfig, providedLocation || (window && window.location && window.location.pathname));
    return Promise.all(matches.map((match) => {
        const { component, name } = match.route;
        if (!component) {
            return undefined;
        }
        const promises = [];
        if (typeof component.loadComponent === 'function') {
            promises.push(component.loadComponent());
            promises.push(match.match.params);
            promises.push(name);
        }
        if (i18n && typeof component.getTransifyCollections === 'function') {
            promises.push(i18n.fetchTranslation({
                langCode: language,
                collectionIds: component.getTransifyCollections(),
            }));
        }
        return Promise.all(promises);
    }));
}
