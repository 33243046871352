import { createStore, combineReducers, compose, applyMiddleware, } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { ENV } from 'src/_shared/config';
import { globalContextDefault, } from 'src/_shared/context/global';
export function createStoreManager(preloadedState = {}, globalParams = globalContextDefault) {
    // Store the reducers
    const currentReducers = rootReducer;
    // Prepare enhancers
    const enhancers = compose(applyMiddleware(thunk.withExtraArgument(globalParams)), ENV !== 'live' &&
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f);
    // Include redux store keys defined in `window.__STORE__`
    // or it will be wiped out by redux's unexpected key detection
    let finalPreloadedState = Object.assign({}, preloadedState);
    if (typeof window === 'object' &&
        window.__STORE__ &&
        typeof window.__STORE__ === 'object') {
        for (const key of Object.keys(window.__STORE__)) {
            if (!currentReducers[key]) {
                currentReducers[key] = (state) => {
                    return state || {};
                };
            }
        }
        // Combines all preloaded states
        finalPreloadedState = { ...finalPreloadedState, ...window.__STORE__ };
    }
    // Create store
    const store = createStore(combineReducers(currentReducers), finalPreloadedState, enhancers);
    // Create an inject reducer function
    // This function adds async reducers, and creates a new combined reducer
    const injectAsyncReducer = (asyncReducers) => {
        Object.keys(asyncReducers).forEach((key) => {
            currentReducers[key] = asyncReducers[key];
        });
        // Replace old reducers by a set of new reducers
        store.replaceReducer(combineReducers(currentReducers));
    };
    return { store, injectAsyncReducer };
}
